import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";

const kv = "../../../images/business/security_service/kv.png";
const bg = "../../../images/business/security_service/business_02_org.png";
const content1 = "../../../images/business/security_service/img1.png";
const content2 = "../../../images/business/security_service/img2.png";
const bg_text = "../../../images/business/security_service/Result.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout addClassName="bg-gray-light">
			<SEO
				description="ALPHA WAVEのセキュリティーサービスの詳細です。セキュリティーセミナーの詳細をご覧ください。"
				title="Security"
			/>
			<section className={CONST.SECTION_AREA_CLASS + " relative"}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Business content <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>事業内容</span></p>
					</div>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + " w-full"} src={kv} />
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h1 className={CONST.H1_CLASS + " 3xl:mb-24"}>実績</h1>
							<p>各種セミナーや、セキュリティー診断サービス、教育サービス等の実績をご紹介します。</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + " w-full"} src={kv} />
					<div data-aos="fade-up" className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS}>
						<div>
							<h2 className={CONST.H2_CLASS}><span className="inline-block">セキュリティー</span><span className="inline-block">セミナー</span></h2>
						</div>
					</div>
				</div>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="absolute w-full z-0 top-112 xl:top-48 right-0" src={bg} />
			</section>

			<section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS + " xl:-mt-20 2xl:-mt-32 3xl:-mt-28"}>
					<div>
						<h6 className={CONST.H6_CLASS + CONST.PC_SHOW_CLASS}>はじめに</h6>
						<p>近年では家電がネットに繋がり仮想通貨やキャッシュレス決済等、どこでもIT化が進んでいる状況になっています。また東京オリンピック開催も近づき、日本という国が海外で情報発信されています。</p>
						<p>それに伴い、情報漏えいや不正アクセス等、重大なセキュリティインシデントが増え、ニュースでも頻繁に取り上げられています。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>まだ、殆どの人が直接の被害は無いかもしれませんが、少しずつ身近に被害が起きうる可能性が高まっています。</p>
						<p>でもご安心下さい。</p>
						<p>本当は比較的簡単な事で被害を防ぐ事が出来たり、攻撃に気づけたりするものです。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>そのきっかけとして本講習にてCTF(後述)を通じ、サイバーセキュリティの知識を深めて最低限の対応が出来る様になって頂きたいと考えております。</p>
						<h6 className={CONST.H6_CLASS}>CTFとは?</h6>
						<p>キャプチャー・ザ・フラッグ（Capture The Flag）。</p>
						<p>様々なＩＴ技術を駆使して、問題を解く競技会イベントです。</p>
						<p>主にセキュリティ技術のイベントとして、参加者に対しコンピュータを守る経験に加え、現実の世界で発見されたサイバー攻撃への対処を学ぶ教育手法として技術スキルの向上を目指して様々なところで企画されていて、最高位は「ハッカーコンテスト」として世界大会が開催されています。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h6 className={CONST.H6_CLASS + CONST.PC_HIDDEN_CLASS}>はじめに</h6>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={content1} />
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<h6 className={CONST.H6_CLASS + CONST.PC_HIDDEN_CLASS}>開催目的</h6>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={content2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-12 relative"}>
					<div className=" z-10">
						<h6 className={CONST.H6_CLASS + CONST.PC_SHOW_CLASS}>開催目的</h6>
						<p className={CONST.LINE_SPACING_PB_CLASS}>サイバーセキュリティに関する知識を「ハッカー」レベルを対象とした講義形式の内容ではなく、ＩＴ全般の基礎レベルを対象としたワークショップ的な競技会形式の内容とし、より身近な技術と知識の向上を目指します。 </p>
						<h6 className={CONST.H6_CLASS}>効果</h6>
						<p>座学ではなく体験としてセキュリティを学ぶことにより深く知識が根付く事が期待できます。</p>
						<p>また、本体験を通じてより高度なセキュリティ技術及びCTF自体に興味を持っていただき、後には各自の自発的な勉強や活動に繋げられるよう促す事も本セミナーの主旨としております。</p>
						<p>講習の実績としてですが、過去にはアルファ・ウェーブ内、神情協内においても同様に開催の機会を頂き、参加いただいた多くの方々からとても楽しかった、ためになったと評価を頂いております事も併せてお伝えさせていただきます。</p>
					</div>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={"hidden xl:block xl:w-3/12 2xl:w-2/12 3xl:w-52 xl:-top-72 2xl:-top-40 3xl:-top-36 -right-10 absolute z-0"} src={bg_text} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " 3xl:-mt-24"}>
				<div className="w-full xl:w-1/12 bg-white pt-9 pl-12">
					<h6 className={CONST.H6_CLASS}>事例</h6>
				</div>
				<div className="w-full xl:w-11/12 bg-white px-12 pb-10">
					<Link to="ctf/eltex">
						<div className={CONST.TABLE_ROW_CLASS + " border-dotted"}>
							<div className={CONST.TABLE_TITLE_CLASS}>2020.4.1</div>
							<div className={CONST.TABLE_TEXT_CLASS}>株式会社エルテックス様よりWEBをテーマにしたCTF開催のご依頼をいただきました。
								<div className={CONST.PC_SHOW_CLASS + " float-right greater_icon mr-8 mt-4"}></div>
							</div>
						</div>
					</Link>
				</div>
			</section>
		</Layout>
	);
}

export default IndexPage;
